exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a.ResetPassword_hyp-back{align-items:center;display:inline-flex;word-break:break-word}@supports (-ms-ime-align:auto){a.ResetPassword_hyp-back{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){a.ResetPassword_hyp-back{word-wrap:break-word}}.ResetPassword_description{margin:0 0 1em}.ResetPassword_no-bottom-margin{margin-bottom:0}.ResetPassword_vd-mode button[disabled]{background-color:var(--theme-button_BackgroundColor,#1f7bc9);box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_BottomBorderColor,#1962a1);color:var(--theme-button_Color,#fff)}.ResetPassword_vd-mode button[disabled]:hover{box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_Disabled_BottomBorderColor,#979797)}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.ResetPassword_form{max-width:46.75em}}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.ResetPassword_form{max-width:none}}", ""]);

// exports
exports.locals = {
	"hyp-back": "ResetPassword_hyp-back",
	"hypBack": "ResetPassword_hyp-back",
	"description": "ResetPassword_description",
	"no-bottom-margin": "ResetPassword_no-bottom-margin",
	"noBottomMargin": "ResetPassword_no-bottom-margin",
	"vd-mode": "ResetPassword_vd-mode",
	"vdMode": "ResetPassword_vd-mode",
	"form": "ResetPassword_form"
};